.fifth_Cont{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.fifth_Cont1{
    display: flex;
    flex-direction: column;
    width: 65%;
}
.fifth_Cont2{
    display: flex;
    width: 35%;
}

.img5{
    padding: 10%;
    border: 10px;
    border-radius: 10px;

}
.head5{
    font-family: 'Poppins', sans-serif;
        font-size: 38px;
    font-weight: bold;
    padding-top: 4%;
    padding-left: 4%;

}
.content5{
    font-family: 'Poppins', sans-serif;
        font-size: 18px;
    line-height: 30px;
    padding-left: 4%;

    text-align: justify;

}

.learn5{
    border-radius: 50px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 1.5%;
    padding: 15px 40px;
    margin-left: 4%;

    background-color:  rgb(162, 0, 141);
}
@media screen and (max-width: 768px) {
    .fifth_Cont {
        flex-direction: column;
    }

    .fifth_Cont1, .fifth_Cont2 {
        width: 100%;
    }

    .img5 {
        padding: 0;
        overflow: hidden;
        margin-top: 4%;
        margin-left: 4%;
        margin-right: 4%;
        border-radius: 20px;
    }

    .head5{
        text-align: center;
        padding: 4%;
        padding-bottom: 0;
    }
     .content5 {
        padding: 0 4%;
    }
    .learnd5{
text-align: center;
}
.fifth_Cont {
    flex-direction: column-reverse;
}
}