.hero {
  background-size: cover;
  background-attachment: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  margin-top: -15%;
  height: 100vh;
  padding-top: 20%;
  padding-left: 5%;
  padding-right: 50px;
  color: #fff;

}

.hero p {
  font-family: 'Poppins', sans-serif;
    font-weight: bold;
  font-size: 20px;
  color: rgb(222, 212, 210);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero #heading h3 {
  color: #fff;
}

.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}

.primary-btn {
  background-color: rgb(162, 7, 141);
  /* padding: 5px 20px; */
  margin-top: 1%;
  border-radius: 30px;
}

.primary-btn:hover {
  transform: scale(1.1);
}

.hero-image-content1 {
  /* background-image: url('/images/hero_img_1.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
  padding: 10px;

}

.mobile-stepper-dot{
  position: absolute;
  bottom: 0;
  display: flex !important;
  justify-content: center !important;
  width: 100%;
  background-color: transparent !important;
}

.mobile-stepper-dot>div>div {
  background-color: #ffdd93;
}


@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }

  .hero {
    background-position: right;
    padding-top: 50%;
    margin-top: 0;
    height: 80vh;
  }

  .hero .row {
    width: 100%;
  }

  #heading {}
}

#heading {
  text-align: center;
}

#heading h3 {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 28px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Poppins', sans-serif;
}

#heading h1 {
  font-size: 75px;
  text-transform: capitalize;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

#heading h2 {
  font-family: 'Poppins', sans-serif;
    font-size: 75px;
  margin-top: -2%;
  font-weight: bold;
  text-transform: capitalize;
}

.imagesection{
  width: 100%;
}

@media screen and (max-width: 1000px) {
.topheight{
margin-top: 77px;
}
}