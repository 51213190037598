.fourth_cont{
    /* margin-top: 2%; */
    /* display: flex; */
    flex-direction: column;
    padding-bottom: 3%;
    padding-top: 1%;
    background-color: #ffdb2a;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;

}
.image_cont5{
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    gap: 7%;
    margin-top: 1.5%;

}
.imagecrousel{
    border-radius: 20px;

}
@media screen and (max-width: 1020px) {
.imagecrousel{
   width: 230px !important;
}
}

@media screen and (max-width: 768px) {
    .imagecrousel{
        width: 300px !important;
     }

    .image_cont5{
        flex-direction: column;
        gap: 7%;
        margin-top: 0;

    }
    .rounded-lg{
        margin: 5% 0;
    }
    .fourth_cont{
        padding-top: 2%;
padding-bottom: 5%;
    }

}

.slider-container {
    text-align: center;
  }
  
  .slider-item {
    margin: auto;
    max-width: 300px; 
  }
  