
/* CSS in style.css */
.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    padding-top: 0;
    background-color: white;
  }
  
  .skillsHeader {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .skillsHeader h1 {
    color: rgb(162, 7, 141);
  }
  
  .skillsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
  }
  
  .skill--box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  /* .skill--box:hover {
    transform: scale(1.15);
  } */
  
  .skill--box img {
    width: 200px;
    height: 170px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .skill--box h3 {
    margin-top: 1rem;
    color: black;
    font-size: 1rem;
    text-align: center;
  }
  
  @media screen and (max-width: 992px) {
    .skill--box {
      flex: 1 1 calc(50% - 1rem);
    }
  }
  