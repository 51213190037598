
.items-container {
    display: flex;
    justify-content: space-evenly; 
    align-items: center;
    flex-wrap: nowrap; 
    padding: 10px;
    gap: 10px;
  }
  
  .image-container {
    flex: 1; 
    max-width: 150px; 
    text-align: center;

  }
  
  .perk-image {
    width: 100%; 
    height: auto; 
    max-width: 200px; 
    max-height: 200px;
    margin-inline: auto;
    object-fit: contain;
  }
  

.image-container2 {
    border-radius: 4px;
    background: #FBC44B;
    display: inline-block;
    padding: 50px;
    flex-shrink: 0;
}

@media (min-width: 768px) {
    .w-24 {
        width: 96px;
    }

    .h-24 {
        height: 96px;
    }
}

@media (max-width: 767px) {
    .flex-wrap {
        flex-direction: column;
    }

    .w-24 {
        width: 48px;
    }

    .h-24 {
        height: 48px;
    }
}


.section3 {
    width: 400px;
    height: 300px;
    flex-shrink: 0;
}





