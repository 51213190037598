.third_Cont{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.third_Cont1{
    display: flex;
    width: 35%;
}
.third_Cont2{
    display: flex;
    width: 65%;
    flex-direction: column;
}

.img3{
    padding: 10%;
    border: 10px;
    border-radius: 10px;

}
.head3{
    font-family: 'Poppins', sans-serif;
        font-size: 38px;
    font-weight: bold;
    padding-top: 4%;

}
.content3{
    font-family: 'Poppins', sans-serif;
        font-size: 18px;
    line-height: 30px;
    padding-right: 4%;
    text-align: justify;

}

.learn{
    border-radius: 50px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 1.5%;
    padding: 15px 40px;
    background-color:  rgb(162, 0, 141);
}
@media screen and (max-width: 768px) {
    .third_Cont {
        flex-direction: column;
    }

    .third_Cont1, .third_Cont2 {
        width: 100%;
    }

    .img3 {
        padding: 0;
        overflow: hidden;
        margin-left: 4%;
        margin-right: 4%;
        border-radius: 20px;
    }

    .head3{
        text-align: center;
        padding: 4%;
        padding-bottom: 0;
    }
     .content3 {
        padding: 0 4%;
    }
    .learnd{
text-align: center;
}
}