  .preloader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    background-color: #fff; /* Optional: Set a background color */
}

.loader {
    height: 15px;
    aspect-ratio: 6;
    display: flex;
}

.loader:before, .loader:after {
    content: "";
    flex: 1;
    padding-left: calc(100% / 6);
    background: radial-gradient(closest-side at calc(100% / 3) 50%, #d12c89 90%, #d12c89) 0 / 75% 100% content-box;
    animation: l20 2s infinite;
}

.loader:after {
    --_s: -1;
}

@keyframes l20 {
    0% {
        transform: scale(var(--_s, 1)) translate(0) rotate(0);
    }
    25% {
        transform: scale(var(--_s, 1)) translate(-25%) rotate(0);
    }
    50% {
        transform: scale(var(--_s, 1)) translate(-25%) rotate(1turn);
    }
    75%, 100% {
        transform: scale(var(--_s, 1)) translate(0) rotate(1turn);
    }
}
