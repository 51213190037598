
/*--------header------------*/

header {
position: relative;
  /* background-color: rgba(255, 255, 255, 0.2); */
  background-color: transparent;
  /* backdrop-filter: blur(10px); 
  opacity: 0.8; */
}
.headernew{
  position: relative;
background-color: #000000BF;
  backdrop-filter: blur(10px); 
  /* opacity: 0.8; */
}
header .nav {
  display: flex !important;
  align-items: center;
}

.logoHeader{
width: 160px;
}
 
header li {
  margin-right: 30px;
}
header ul {
  /* padding: 30px 20px; */
}
header ul li a {
  color: black;
  font-weight: 500;
  transition: 0.3s;
}
header ul li a:hover {
 /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
}
.start {
  margin-top: 15px;
  /* background-color: #FBC44B; */
  padding: auto;
  color: #fff;
  width: 200px;
  padding-left: 40px;
  /* clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%); */
}
.toggle {
  display: none;
}
.flexSB {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.headlogo{
  /* width: 150px; */
}
.right-menu-mb{
  display: none;
}
.right-menu-des{
  display: block;
}
.desktop-search{
  display: flex;
}
.mobile-search{
  display: none;
}
/*--------header------------*/


@media screen and (max-width: 1000px) {
  .start {
    padding: 8px 10px;
    clip-path: none;
  }
  header {
    background-color: white;
    position: relative;
 
  }
  .headernew{
    background-color: white;
    position: relative;
  }
  .logoHeader{
width: 100px;
padding-top: 5px;

  }

  .start {
    padding: auto;
    color: #fff;
    width: 150px;
    margin-top: -5px;
  }

  header ul.flexSB {
    display: none;
  }

  header ul li {
    margin-bottom: 20px;
  }
  .flexSB {
align-items: normal;
}
  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
margin-top: 16px;
margin-right: 20px;
    right:0;
  }
  .mobile-nav {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    z-index: 1;
    top: 8vh;
    margin-top: -5px;
    left: 0;
    width: 100%;
    padding-left: 30px;
    text-align: center;
    padding-top: 20px;
    background-color: white;

  }
.listitemmobile{
  /* text-align: center; */
  display: flex;
  text-align: center;
  justify-content: center;
}
.right-menu-mb{
  display: flex;
  align-items: center;
}
.right-menu-des{
  display: none;
}
.mobile-search{
  display: flex;
}
/* .desktop-search{
  display: none;
} */
}

.headbutton{
  background-color: rgb(162,0,141);
  border-radius: 20px;
  padding: 5px 15px;
  /* margin-left: auto;
  margin-right: auto; */
  display: flex;

}
.headbutton:hover{
transform: scale(1.1);
}
