.img_static{
    width: 100%;
}
.img_about{
    margin-top: 100px ;
}

@media screen and (max-width: 700px) {
        /* .img_static{
        height: 25lvh;
    } */
    .img_about{
        margin-top: 80px ;
    }
}